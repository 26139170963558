import React from 'react';

import { useHandleWithLegacyURL } from '~/hooks';

const Redirect: React.FC = () => {
  useHandleWithLegacyURL({ isSlugPage: true });

  return <></>;
};

export default Redirect;
